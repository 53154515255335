import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/collections/[network]/[address]/(components)/Like/BtnHeart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Homepage/BtnShop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Homepage/ClientReferralCheck.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Homepage/Hero/NewHero/Buttons/IconButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Homepage/Hero/NewHero/Buttons/SignUpBtn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Homepage/Hero/NewHero/Dialog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Homepage/Slider/Slide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Homepage/Trailer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/SkeletonLoader/style.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["revertSaleItemPrice"] */ "/vercel/path0/src/services/seaport-client.ts");
