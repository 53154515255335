"use client";

import { useAlertStore } from "@/components/ui/Alert";
import { toggleLikeCollection } from "@/server/actions/like/collection";
import { HeartIcon } from "@heroicons/react/24/outline";
import { useParams } from "next/navigation";
import { useState } from "react";
import IconHeartSolid from "../../../../../../components/ui/Icons/IconHeartSolid";
import { useIsUserLoggedIn } from "@/hooks/useIsUserLoggedIn";

type Props = {
  liked: boolean;
  likesCount: number;
  network?: string;
  address?: string;
};

export default function BtnHeart({
  liked,
  likesCount,
  network,
  address,
}: Props) {
  const [likedOptimistic, setLikedOptimistic] = useState(liked);
  const [optimisticLikesCount, setOptimisticLikesCount] = useState(likesCount);

  const { setAlertMessage, setAlertType, setOpenAlert } = useAlertStore();

  const params = useParams();
  const { checkLoginStatus } = useIsUserLoggedIn();

  const addressToUse = address
    ? address.length > 0
      ? address
      : (params?.address as string)
    : (params?.address as string);

  const networkToUse = network
    ? network.length > 0
      ? network
      : (params?.network as string)
    : (params?.network as string);

  const handleToggleLike = async () => {
    try {
      await toggleLikeCollection(liked || false, addressToUse, networkToUse);
    } catch (error) {
      console.log("Error toggling like collection:", error);
    }
  };

  const handleClick = async (e: any) => {
    e.preventDefault();
    // 1) Check if user is logged in
    const isLoggedIn = await checkLoginStatus();
    if (!isLoggedIn) return;
    // Save previous state in case we need to revert
    const prevLiked = likedOptimistic;
    const prevLikesCount = optimisticLikesCount;

    // Optimistic update first
    const newLiked = !likedOptimistic;
    setLikedOptimistic(newLiked);
    setOptimisticLikesCount(
      newLiked ? optimisticLikesCount + 1 : optimisticLikesCount - 1
    );

    try {
      await handleToggleLike();
    } catch (error) {
      // Revert the optimistic updates
      setLikedOptimistic(prevLiked);
      setOptimisticLikesCount(prevLikesCount);

      // Show login alert
      setAlertMessage("Please log in to like this Collection.");
      setAlertType("default");
      setOpenAlert(true);
    }
  };

  return (
    <div className="flex items-center">
      <button
        type="submit"
        className="ml-4 flex items-center justify-center rounded-md px-3 py-3"
        onClick={handleClick}
        aria-label="Like this NFT"
      >
        {likedOptimistic ? (
          <>
            <IconHeartSolid />
            <span className="sr-only">Unlike this NFT</span>
          </>
        ) : (
          <>
            <HeartIcon className="h-6 w-6 hover:text-dark-pri" />
            <span className="sr-only">Like this NFT</span>
          </>
        )}
      </button>
      {optimisticLikesCount > 0 && (
        <span className="text-sm">{optimisticLikesCount}</span>
      )}
    </div>
  );
}
