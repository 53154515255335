"use client";

import React, { useState } from "react";
import "./styles.css";
import { TypewriterEffect } from "@/components/ui/typewriter-effect";
import { cn } from "@/lib/utils";
import Noise from "./Noise";
import IntroNoise from "./IntroNoise";

const firstWords = [
  { text: "Hi" },
  { text: "there," },
  { text: "I'm" },
  { text: "Dusty" },
  { text: "Starchaser." },
  { text: "Welcome" },
  { text: "to" },
  { text: "Rodeyo!" },
  { text: "🤠" },
];

const secondWords = [
  { text: "Here's" },
  { text: "some" },
  { text: "of" },
  { text: "the" },
  { text: "stuff" },
  { text: "you" },
  { text: "can" },
  { text: "do" },
  { text: "here:" },
];

const thirdWords = [
  { text: "Earn" },
  { text: "rewards" },
  { text: "that" },
  { text: "can" },
  { text: "be" },
  { text: "redeemed" },
  { text: "for" },
  { text: "crypto!" },
];

const fourthWords = [
  { text: "Buy" },
  { text: "and" },
  { text: "Sell" },
  { text: "NFTs..." },
  { text: "we" },
  { text: "don't" },
  { text: "charge" },
  { text: "fees!!" },
];

const fifthWords = [{ text: "Play" }, { text: "games" }, { text: "🕹️" }];

const sixthWords = [
  { text: "Chat" },
  { text: "with" },
  { text: "friends" },
  { text: "💬" },
];

const seventhWords = [
  { text: "Create" },
  { text: "art" },
  { text: "🎨" },
  { text: "and" },
  { text: "NFT" },
  { text: "collections" },
];
const eigthWords = [
  { text: "...and" },
  { text: "more" },
  { text: "to" },
  { text: "come!" },
];

const ninthWords = [
  { text: "Thanks" },
  { text: "for" },
  { text: "stopping" },
  { text: "by." },
  { text: "Safe" },
  { text: "travels" },
  { text: "through" },
  { text: "the" },
  { text: "void!" },
  { text: "🚀" },
];

const tenthWords = [{ text: "" }];

const dialogs = [
  firstWords,
  secondWords,
  thirdWords,
  fourthWords,
  fifthWords,
  sixthWords,
  seventhWords,
  eigthWords,
  ninthWords,
  tenthWords,
];

const DialogManager: React.FC = () => {
  const [introDone, setIntroDone] = useState(false);

  // For the main typewriter dialogs:
  const [currentIndex, setCurrentIndex] = useState(0);
  // 'typing' = fade in & type, 'fading' = fade out in progress
  const [phase, setPhase] = useState<"typing" | "fading">("typing");

  // Called by TypewriterEffect once it finishes typing one line
  const handleTypingComplete = () => {
    setPhase("fading");
  };

  // Called by the fade-out animation
  const handleAnimationEnd = () => {
    if (phase === "fading") {
      const nextIndex = currentIndex + 1;
      if (nextIndex < dialogs.length) {
        setCurrentIndex(nextIndex);
        setPhase("typing");
      } else {
        console.log("All dialogs done! Showing final Noise...");
      }
    }
  };

  return (
    <div className="relative">
      {/* 1) Show IntroNoise (Starting transmission...) until it’s removed */}
      {!introDone && <IntroNoise onDone={() => setIntroDone(true)} />}

      {/* 2) After IntroNoise is done, show the typewriter dialogs */}
      {introDone && (
        <>
          <div
            className={cn(
              currentIndex < dialogs.length - 1 ? "dialog-box" : "hidden"
            )}
          >
            <div
              className={cn(phase === "typing" ? "fade-in" : "fade-out")}
              onAnimationEnd={handleAnimationEnd}
            >
              <TypewriterEffect
                key={currentIndex}
                words={dialogs[currentIndex]}
                onComplete={handleTypingComplete}
              />
            </div>
          </div>

          {/* 3) Once the last dialog is shown, display the final Noise */}
          {currentIndex === dialogs.length - 1 && <Noise />}
        </>
      )}
    </div>
  );
};
export default DialogManager;
