"use client";

import { useLoginModalStore } from "@/components/magic/auth/LoginModal/ModalWrapper";
import PrimaryButton from "@/components/ui/Forms/Button/PrimaryBtn";
import React from "react";

export default function SignUpBtn() {
  const { setOpenLoginModal } = useLoginModalStore();
  return (
    <PrimaryButton
      onClick={() => setOpenLoginModal(true)}
      style="w-full !bg-gradient-to-r from-primary to-[#970BD9] lg:max-w-[180px] "
    >
      Go to sign up
    </PrimaryButton>
  );
}
