"use client";

import { useLoginModalStore } from "@/components/magic/auth/LoginModal/ModalWrapper";
import { useMagic } from "@/context/MagicProvider";
import { useAccount } from "wagmi";

/**
 * Returns a function that checks if user is logged in.
 * If not, it triggers the login modal and returns false.
 */
export function useIsUserLoggedIn() {
  const { magic } = useMagic();
  const { isConnected } = useAccount();
  const { setOpenLoginModal } = useLoginModalStore();

  const checkLoginStatus = async (): Promise<boolean> => {
    // 1. If wagmi "isConnected" is true, user is logged in via wallet
    if (isConnected) {
      return true;
    }

    // 2. If not connected to a wallet, check Magic login status
    const isLoggedIn = await magic?.user.isLoggedIn();
    if (isLoggedIn) {
      return true;
    } else {
      // 3. If neither wagmi nor Magic is connected, show login modal
      setOpenLoginModal(true);
      return false;
    }
  };

  return {
    checkLoginStatus,
  };
}
