"use client";
import { useState, useEffect } from "react";

export default function Noise() {
  const [visible, setVisible] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [removed, setRemoved] = useState(false);

  useEffect(() => {
    // After 3 seconds, hide text + start collapse
    const timer = setTimeout(() => {
      setVisible(false);
      setCollapsed(true);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  // Once the collapse animation finishes, remove from DOM
  const handleTransitionEnd = () => {
    if (collapsed) {
      setRemoved(true);
    }
  };

  // If removed, render nothing
  if (removed) return null;

  return (
    <div
      onTransitionEnd={handleTransitionEnd}
      className={`
      top-0 lg:top-[80px] w-full lg:w-[220px] p-6 text-gray-300 absolute lg:-left-[220px]
        overflow-hidden
        transition-transform duration-700 
        origin-center 
        ${collapsed ? "scale-y-0" : "scale-y-100"}
      `}
    >
      <video
        src="https://res.cloudinary.com/dtb9hpgez/video/upload/v1742605510/miscellaneous/white-noise-edit-3_wosu1l.mov"
        autoPlay
        loop
        muted
        playsInline
        // For older iOS browsers:
        webkit-playsinline="true"
        // disable PiP and native controls:
        disablePictureInPicture
        controls={false}
        className="w-full h-auto opacity-60"
      />

      {/* Only render the text while `visible` is true */}
      {visible && (
        <div className="absolute inset-0 flex items-center justify-center">
          <p className="text-center font-bold">
            End of <br />
            Transmission
          </p>
        </div>
      )}
    </div>
  );
}
