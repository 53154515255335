"use client";
import { useLoginModalStore } from "@/components/magic/auth/LoginModal/ModalWrapper";

type Props = {
  children: React.ReactNode;
  ariaLabel: string;
};

export default function IconButton({ children, ariaLabel }: Props) {
  const { setOpenLoginModal } = useLoginModalStore();
  return (
    <button
      onClick={() => setOpenLoginModal(true)}
      aria-label={ariaLabel}
      title={ariaLabel}
      className="w-12 h-12 md:w-10 md:h-10 bg-gray-700 rounded-full flex items-center justify-center"
    >
      <span className="text-white text-xl">{children}</span>
    </button>
  );
}
